<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-select
            codeGroupCd="CLOSED_SPACE_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="closedSpaceTypeCd"
            label="밀폐공간 종류"
            v-model="searchParam.closedSpaceTypeCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-card :title="`[${map.mapName}] 도면 정보`" class="cardClassDetailForm closedSpace-card">
      <template slot="card-description">
        <q-badge color="red" text-color="white" label="도면이미지에 마우스 우클릭을 통해 밀폐공간위치를 추가하세요." />
      </template>
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn label="LBL0000209" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div :style="`height: ${zoomer.height}px;`" class="zommer-container col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
          <panZoom v-if="mapExists" ref="panZoom" selector="#zoomerImage" :options="zoomer.options">
            <q-img
              id="zoomerImage"
              :src="map.mapSrc"
              :style="`width: ${(zoomer.height - 30) * map.ratio}px; height: ${zoomer.height - 30}px;`"
            >
              <q-menu
                touch-position
                context-menu
                @before-show="beforeShow"
              >
                <q-list dense style="min-width: 100px">
                  <q-item clickable v-close-popup @click="onItemClick">
                    <q-item-section avatar class="closedSpace-mark-add">
                      <q-icon name="add" color="red" />
                    </q-item-section>
                    <q-item-section>밀폐공간명</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
              <c-map-marker 
                v-for="(mark, idx) in map.marks"
                :key="idx"
                class="mark-closedSpace"
                :style="`transform: translate(${(mark.locationXcoordinate * zoomer.ratio) - 15}px, ${(mark.locationYcoordinate * zoomer.ratio) - 25}px);z-index: auto; user-select: auto; width: 30px; height: 30px;`"
                :isSelected="true">
                <template v-slot:innerMark>
                  <q-menu 
                    :ref="'markedproxy' + mark.idx" 
                    :content-class="'closedSpace-menu-layer'"
                    anchor="top right"
                    self="top left">
                    <q-card 
                      flat bordered 
                      @click="linkClick(mark)"
                      :class="['closedSpace-menu-card']">
                      <q-item class="closedSpace-card-section closedSpace-card-item">
                        <q-item-section>
                          <q-item-label>
                            <q-badge rounded color="light-blue" :label="getSpaceTypeName(mark.closedSpaceTypeCd)" /> 
                          </q-item-label>
                        </q-item-section>
                        <q-item-section side>
                          <q-icon name="delete" color="red" class="cursor-pointer" @click.stop="removeClick(mark)" />
                        </q-item-section>
                      </q-item>
                      <q-card-section class="closedSpace-card-section cursor-pointer">
                        <q-icon 
                          name="chevron_right" 
                          class="text-indigo" 
                        />
                        {{mark.heaClosedSpaceName}}
                      </q-card-section>
                    </q-card>
                  </q-menu>
                </template>
              </c-map-marker>
              <!-- <q-img
                v-for="(mark, idx) in map.marks"
                :key="idx"
                class="mark-closedSpace"
                :style="`transform: translate(${(mark.locationXcoordinate * zoomer.ratio) - 15}px, ${(mark.locationYcoordinate * zoomer.ratio) - 15 }px);z-index: auto; user-select: auto; width: 30px; height: 30px;`"
                :src="mark.closedSpaceDownPath ? mark.closedSpaceDownPath : require('@/assets/images/xxx.png')"
              >
                <q-menu 
                  :ref="'markedproxy' + mark.idx" 
                  :content-class="'closedSpace-menu-layer'"
                  anchor="top right"
                  self="top left">
                  <q-card 
                    flat bordered 
                    @click="linkClick(mark)"
                    :class="['closedSpace-menu-card']">
                    <q-item class="closedSpace-card-section closedSpace-card-item">
                      <q-item-section>
                        <q-item-label>
                          <q-badge rounded color="light-green" :label="mark.machineryClassificationName" /> / 
                          <q-badge rounded color="light-blue" :label="mark.itemNo" /> 
                        </q-item-label>
                      </q-item-section>
                      <q-item-section side>
                        <q-icon name="delete" color="red" class="cursor-pointer" @click.stop="removeClick(mark)" />
                      </q-item-section>
                    </q-item>
                    <q-card-section class="closedSpace-card-section cursor-pointer">
                      <q-icon 
                        name="chevron_right" 
                        class="text-indigo" 
                      />
                      {{mark.machineryName}}
                    </q-card-section>
                  </q-card>
                </q-menu>
              </q-img> -->
            </q-img>
          </panZoom>
        </div>
        <div :style="`height: ${zoomer.height}px; overflow-y: auto;`" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 closedSpace-map-list">
          <q-list bordered padding>
            <template v-for="(mapInfo, idx) in zoomer.maps">
              <q-item :key="idx" clickable @click="mapSelect(mapInfo)">
                <q-item-section class="q-ml-none">
                  <q-img
                    id="zoomerImage"
                    :src="mapInfo.mapSrc"
                    :style="`width: 150px; height: 150px;`"
                  >
                    <div class="absolute-bottom text-subtitle1 text-center">
                      {{mapInfo.mapName}}
                    </div>
                  </q-img>
                </q-item-section>
              </q-item>
              <q-separator v-if="(idx + 1) < zoomer.maps.length" :key="idx + 'sep'" spaced></q-separator>
            </template>
          </q-list>
        </div>
      </template>
    </c-card>
    <q-dialog v-model="prompt" persistent>
      <q-card style="width: 100%">
        <q-form ref="editForm">
          <q-card-section>
            <div class="text-h6">밀폐공간 정보를 입력하세요.</div>
          </q-card-section>
          <q-card-section class="q-pt-none" style="padding-top:20px!important">
            <c-plant required type="search" name="plantCd" v-model="saveData.plantCd" />
          </q-card-section>
          <q-card-section class="q-pt-none">
            <c-select 
              required
              label="종류"
              codeGroupCd="CLOSED_SPACE_TYPE_CD"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="closedSpaceTypeCd" 
              v-model="saveData.closedSpaceTypeCd" />
          </q-card-section>
          <q-card-section class="q-pt-none">
            <c-text required label="밀폐공간명" name="heaClosedSpaceName" v-model="saveData.heaClosedSpaceName" />
          </q-card-section>
          <q-card-section class="q-pt-none">
            <c-textarea label="비고" name="remark" :rows="3" v-model="saveData.remark" />
          </q-card-section>
        </q-form>
        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="취소" @click="cancelCloseSpace" />
          <q-btn flat label="저장" @click="setCloseSpace" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'closedSpaceMap',
  props: {
  },
  data() {
    return {
      prompt: false,
      searchParam: {
        plantCd: null,
        closedSpaceTypeCd: null,
      },
      saveData: {
        plantCd: null,
        closedSpaceTypeCd: null,
        heaClosedSpaceName: null,
        remark: null,
      },
      mapMessage: '',
      mapSrc: require('@/assets/images/no-image.png'),
      editable: true,
      detailUrl: '',
      grid: {
        columns: [],
        data: [],
        height: '500px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      options: {
        minZoom: 0.5,
        maxZoom: 3,
        initialZoom: 1,
        initialX: 1800,
        initialY: 200,
        bounds: true,
        boundsPadding: 0.1,
        zoomDoubleClickSpeed: 1, 
        onTouch: function() {
          return false;
        }
      },
      zoomer: {
        height: 600,
        ratio: 1,
        style: 'width: 100%; height: 100%;',
        imageStyle: 'width: 90%; height: 90%;',
        value: 100,
        interval: 10,
        options: {
          minZoom: 0.5,
          maxZoom: 3,
          initialZoom: 1,
          initialX: 800,
          initialY: 200,
          bounds: true,
          boundsPadding: 0.1,
          zoomDoubleClickSpeed: 1, 
          onTouch: function(e) {
            return false;
          }
        },
        data: {
          slide: 0,
          mapSrc: require('@/assets/images/no-image.png'),
          fullscreen: false,
          maps: [],
        },
        menu: {
          offsetX: 0,
          offsetY: 0,
        },
        currentMap: {
          sopMapId: ''
        },
        maps: [],
      },
      data: {
        slide: 0,
        mapSrc: require('@/assets/images/no-image.png'),
        fullscreen: false,
        maps: [],
      },
      row: {},
      codeNames: [],
      tempMapDetails: [],
      listUrl: '',
      insertUrl: '',
      updateUrl: '',
      isSave: false,
      deleteUrl: '',
      isInsert: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    window.addEventListener('resize', this.setSize);
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  computed: {
    map() {
      let map = {
        sopMapId: '',
        mapSrc: '',
        mapName: '',
        ratio: 0,
        marks: []
      }
      if (this.mapExists) {
        map = this.$_.find(this.zoomer.maps, { sopMapId: this.zoomer.currentMap.sopMapId })
      }
      return map;
    },
    mapExists() {
      return this.zoomer.maps && this.zoomer.maps.length > 0 && Boolean(this.zoomer.currentMap.sopMapId)
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.hea.closedspaceMap.list.url
      this.insertUrl = transactionConfig.hea.closedspaceMap.insert.url;
      this.updateUrl = transactionConfig.hea.closedspaceMap.update.url;
      this.deleteUrl = transactionConfig.hea.closedspaceMap.delete.url;

      this.$comm.getComboItems('CLOSED_SPACE_TYPE_CD').then(_result => {
        this.codeNames = _result
      });

      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.$set(this.zoomer, 'maps', _result.data)
        if (this.zoomer.maps && this.zoomer.maps.length > 0 && !this.zoomer.currentMap.sopMapId) {
          this.$set(this.zoomer.currentMap, 'sopMapId', this.zoomer.maps[0].sopMapId)
        }
      },);
    },
    // getDetail(sopMapId) {
    //   if (!sopMapId) return;
    //   this.$http.url = this.$format(this.detailUrl, sopMapId);
    //   this.$http.type = 'GET';
    //   this.$http.request((_result) => {
    //     /**
    //      * 특정 지도 정보의 위치정보만 refresh
    //      */
    //     if (this.zoomer.maps && this.zoomer.maps.length > 0
    //       && _result.data && _result.data.marks && _result.data.marks.length > 0) {
    //       let idx = this.$_.findIndex(this.zoomer.maps, { sopMapId: sopMapId })
    //       this.$set(this.zoomer.maps[idx], 'marks', _result.data.marks)
    //     }
    //   },);
    // },
    setSize() {
      this.zoomer.height = window.innerHeight - 200;
      this.zoomer.ratio = (this.zoomer.height - 30) / 575;
      // this.zoomer.style = `width: 100%; height: ${height}px;`
      // this.zoomer.imageStyle = `width: 90%; height: ${height - 30}px;`
    },
    beforeShow(event) {
      let ratio = 575 / (this.zoomer.height - 30)
      this.$set(this.zoomer.menu, 'offsetX', event.offsetX * ratio)
      this.$set(this.zoomer.menu, 'offsetY', event.offsetY * ratio)
    },
    mapSelect(mapInfo) {
      this.$set(this.zoomer.currentMap, 'sopMapId', mapInfo.sopMapId)
    },
    onItemClick() {
      this.prompt = true;
      this.isInsert = true;
      this.$set(this.saveData, 'plantCd', null);
      this.$set(this.saveData, 'closedSpaceTypeCd', null);
      this.$set(this.saveData, 'heaClosedSpaceName', null);
      this.$set(this.saveData, 'locationYcoordinate', '');
      this.$set(this.saveData, 'locationXcoordinate', '');
      this.$set(this.saveData, 'remark', null);
      this.$set(this.saveData, 'heaClosedSpaceId', '');
    },
    saveClick() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGSAVE', // 저장하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$_.forEach(this.data.maps, item => {
            item.regUserId = this.$store.getters.user.userId;
            item.chgUserId = this.$store.getters.user.userId;
          });
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    removeClick(mark) {
      this.$http.url = this.$format(this.deleteUrl, mark.heaClosedSpaceId);
      this.$http.type = 'DELETE';
      this.$http.request(() => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.getList();
        // this.getDetail(mark.sopMapId);
      },);
    },
    getSpaceTypeName(_cd) {
      let list = this.$_.filter(this.codeNames, { code: _cd });
      if (list.length == 1) {
        return list[0].codeName;
      } else {
        return '';
      }
    },
    setCloseSpace() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          let key = '';
          let x = '';
          let y = '';
          if (this.isInsert) {
            this.$http.url = this.insertUrl;
            this.$http.type = 'POST';
            x = this.zoomer.menu.offsetX
            y = this.zoomer.menu.offsetY
          } else {
            this.$http.url = this.updateUrl;
            this.$http.type = 'PUT';
            key = this.saveData.heaClosedSpaceId
            x = this.saveData.locationXcoordinate
            y = this.saveData.locationYcoordinate
          }
          this.$http.param = {
            heaClosedSpaceId: key,  // 작업위치 번호
            sopMapId: this.map.sopMapId,  // 지도 일련번호
            locationXcoordinate: x,  // 작업위치 X좌표
            locationYcoordinate: y,  // 작업위치 Y좌표
            plantCd: this.saveData.plantCd,  
            heaClosedSpaceName: this.saveData.heaClosedSpaceName,  
            closedSpaceTypeCd: this.saveData.closedSpaceTypeCd,  
            remark: this.saveData.remark,  
            regUserId: this.$store.getters.user.userId,  // 등록자 ID
          };
          this.$http.request(() => {
            this.prompt = false;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getList();
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      })
    },
    cancelCloseSpace() {
      this.prompt = false;
      this.$set(this.saveData, 'plantCd', null);
      this.$set(this.saveData, 'closedSpaceTypeCd', null);
      this.$set(this.saveData, 'heaClosedSpaceName', null);
      this.$set(this.saveData, 'locationYcoordinate', '');
      this.$set(this.saveData, 'locationXcoordinate', '');
      this.$set(this.saveData, 'remark', null);
      this.$set(this.saveData, 'heaClosedSpaceId', '');
    },
    linkClick(mark) {
      this.prompt = true;
      this.isInsert = false;
      this.$set(this.saveData, 'plantCd', mark.plantCd);
      this.$set(this.saveData, 'closedSpaceTypeCd', mark.closedSpaceTypeCd);
      this.$set(this.saveData, 'heaClosedSpaceName', mark.heaClosedSpaceName);
      this.$set(this.saveData, 'locationYcoordinate', mark.locationYcoordinate);
      this.$set(this.saveData, 'locationXcoordinate', mark.locationXcoordinate);
      this.$set(this.saveData, 'remark', mark.remark);
      this.$set(this.saveData, 'heaClosedSpaceId', mark.heaClosedSpaceId);
      
    }
  }
};
</script>
<style lang="sass">
.custom-zoomer
  border: solid 1px silver
  background-color: white !important
.mark-closedSpace
  background: unset !important
  cursor: pointer
    
.closedSpace-menu-layer
  border-radius: 10px !important
  max-width: 600px !important
.closedSpace-menu-card
  padding-top: 5px
  padding-bottom: 5px
  margin: 6px
  .closedSpace-card-section
    padding: 0 10px 0 10px
  .closedSpace-card-item
    min-height: auto

.zommer-container
  .vue-pan-zoom-scene
    overflow: auto

.closedSpace-card
  .customCardbody
    margin: 0 !important
    padding: 0 !important

.closedSpace-map-list
  padding-right: 0 !important
  .q-item
    flex-direction: column
    align-items: center

.closedSpace-mark-add
  min-width: 0 !important
  padding: 0 !important

.mark-closedSpace
  background: unset !important
  cursor: pointer
</style>